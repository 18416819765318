import { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, Heading, HStack, ListItem, OrderedList, Stack, Text } from "@chakra-ui/react";
import { BuoyRow } from "../../types";
import supabase from "../../config/supabaseClient";
import BuoyTable from "./components/BuoyTable";
import BuoyMap from "./components/BuoyMap";
import { EditIcon } from "@chakra-ui/icons";
import { AuthContext } from "../../App";
import { Link } from "react-router-dom";

const Buoys = () => {
    const [buoys, setBuoys] = useState<BuoyRow[]>([]);
    const { isAdmin } = useContext(AuthContext);

    useEffect(() => {
        const fetchBuoys = async () => {
            const { data, error } = await supabase.from("buoys").select();

            if (error) {
                setBuoys([]);
                return;
            }

            setBuoys(data);
        };

        fetchBuoys();
    }, []);

    return (
        <Stack spacing={2}>
            <HStack justifyContent={"space-between"}>
                <Heading>Bøyer</Heading>
                {isAdmin && (
                    <Link to={"/boyer/rediger"}>
                        <Button leftIcon={<EditIcon />}>Rediger bøyer</Button>
                    </Link>
                )}
            </HStack>
            <Stack spacing={4}>
                <Heading size={"md"}>Bøyereglement</Heading>
                <Text color={"gray"}>
                    Vedtatt av NHKs styre 9. april 1986 med endringer på Generalforsamlingen 26. mars 2009, 20.mars 2014
                    og 31.mars 2022
                </Text>
                <Text>
                    Norsk Havseiler- og Krysserklubb har lagt ut bøyer på gunstige steder langs kysten med tillatelse av
                    havnemyndigheter og grunneiere. Bøyene ligger på steder som er angitt i en spesiell bøyeoversikt.
                    Bøyene, som fortrinnsvis ligger i naturhavner, er hvite eller røde og merket med klubbens emblem,
                    samt teksten: PRIVAT NHK maks. 24 t. ("t" for timer).
                </Text>
                <Box paddingLeft={"24px"}>
                    <OrderedList>
                        <ListItem>
                            NHK's bøyer får kun brukes av båter som er under kommando av et NHK-medlem, og som fører
                            NHK's stander. Standeren skal føres på reglementert måte. Båten må videre ha lett synlig
                            navn.
                        </ListItem>
                        <ListItem>Bøyene kan kun benyttes i inntil 24 timer, og ikke for lengre opphold.</ListItem>
                        <ListItem>
                            NHK fraskriver seg ethvert ansvar for skader eller ulykkesforløp som måtte oppstå p.g.a.
                            bøyehavari. Bøyene er imidlertid lagt ut med all mulig forsiktighet, og under hensyntagen
                            til de stedlige forhold.
                        </ListItem>
                        <ListItem>
                            Vis hensyn mot bøyene. Medvirk til at bøyene blir brukt fornuftig, og i overensstemmelse med
                            reglementet. Meddel NHK med en gang hvis en bøye mangler, eller driver av.
                        </ListItem>
                        <ListItem>
                            Vis bort båter som ligger i bøyene, hvis de ikke oppfyller de krav som er nevnt i
                            bøyereglementet. Henvis da til at bøyene er lagt ut med tillatelse av havnemyndigheter, og i
                            overensstemmelse med deres instruksjoner.
                        </ListItem>
                    </OrderedList>
                </Box>
                <Divider borderColor={"gray"} />
                <Text>
                    Mange av NHKs medlemmer har gjennom sommeren uoppfordret renset bøyene for skjell o.a. når de har
                    benyttet dem, og dette sparer klubben for mange kostnader. NHK takker for "dugnadsånden" dere
                    utviser!
                </Text>
            </Stack>

            <Heading size={"md"}>Oversikt</Heading>
            <BuoyTable buoys={buoys} />
            <BuoyMap buoys={buoys} />
        </Stack>
    );
};

export default Buoys;
