import { EditIcon } from "@chakra-ui/icons";
import { Button, Heading, HStack, SimpleGrid, Stack, Text, useToast, Link as ChakraLink } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import supabase from "../../config/supabaseClient";
import { ProductRow } from "../../types";
import ProductCard from "./components/ProductCard";

const Store = () => {
    const [products, setProducts] = useState<ProductRow[]>([]);
    const navigate = useNavigate();
    const toast = useToast();
    const { user, isAdmin, token } = useContext(AuthContext);

    useEffect(() => {
        const fetchProducts = async () => {
            const { data, error } = await supabase.from("products").select().order("in_stock", { ascending: false });

            if (error) {
                setProducts([]);
                return;
            }

            if (data) {
                setProducts(data);
            }
        };

        fetchProducts();
    }, []);

    const handleBuy = async (product: ProductRow) => {
        const confirmText = "Ved å trykke OK bekrefter du at du ønsker å bestille " + product.name;
        if (!window.confirm(confirmText)) return;

        const response = await fetch("https://dxkczmnvoadzwabfzont.supabase.co/functions/v1/send-email", {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ userEmail: user?.email, product: product, amt: 1 }),
        });

        if (!response.ok) {
            toast({
                title: "En feil oppsto",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        toast({
            title: "Din ordre er sendt inn",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    const handleEdit = (id: number) => {
        navigate(`/butikk/opprett/${id}`);
    };

    const handleDelete = async (id: number) => {
        const confirmText = "Er du sikker på at du vil slette produktet?";
        if (!window.confirm(confirmText)) return;

        const { error } = await supabase.from("products").delete().eq("id", id);
        if (error) {
            toast({
                title: "Kunne ikke slette produkt",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const { error: imgError } = await supabase.storage.from("images").remove([`store/${id}`]);

        if (imgError) {
            toast({
                title: "Kunne ikke slette produktets bilde",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        setProducts(products.filter((product) => product.id !== id));
        toast({
            title: "Produkt slettet",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    return (
        <Stack spacing={4}>
            <HStack justifyContent={"space-between"}>
                <Heading>Klubbeffekter</Heading>
                {isAdmin && (
                    <Link to={"/butikk/opprett"}>
                        <Button leftIcon={<EditIcon />}>Legg til produkt</Button>
                    </Link>
                )}
            </HStack>
            <Text>
                Klubbeffekter bestilles av Pål Sture Skogfelt på epost{" "}
                <ChakraLink
                    color={"blue"}
                    href="mailto:sekretaer@havseilerklubben.no?subject=Bestilling%20av%20klubbeffekter&body=Hei%2C%0D%0A%0D%0AJeg%20%C3%B8nsker%20%C3%A5%20bestille%20SETT%20INN%20PRODUKT%20HER%20fra%20NHK's%20nettbutikk.%0D%0A%0D%0AMed%20vennlig%20hilsen%2C%0D%0ADITT%20NAVN%20HER"
                >
                    sekretaer@havseilerklubben.no
                </ChakraLink>{" "}
                eller mobil 905 62 062
            </Text>
            <Text>Deretter utføres betaling til konto 1620 14 99813</Text>
            <Text>Alle priser er inkludert 50,- for porto</Text>
            <SimpleGrid columns={{ sm: 2, md: 3, lg: 4 }} spacing={4}>
                {products.map((product) => (
                    <ProductCard
                        key={product.id}
                        product={product}
                        onEdit={isAdmin ? handleEdit : undefined}
                        onDelete={isAdmin ? handleDelete : undefined}
                        onBuy={handleBuy}
                    />
                ))}
            </SimpleGrid>
        </Stack>
    );
};
export default Store;
