import { AuthChangeEvent, User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import supabase from "./supabaseClient";

const useAuth = () => {
    const [event, setEvent] = useState<AuthChangeEvent>();
    const [user, setUser] = useState<User | null>();
    const [token, setToken] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            const {
                data: { session },
                error,
            } = await supabase.auth.getSession();

            if (error) {
                setUser(null);
                setIsAdmin(false);
                setIsLoading(false);
                setToken("");
            }
            if (session) {
                setUser(session.user);
                setToken(session.access_token);
                const isAdminResponse = await supabase.from("admins").select().eq("id", session.user.id).single();
                setIsAdmin(!!isAdminResponse.data);
                setIsLoading(false);
            }
        };

        supabase.auth.onAuthStateChange(async (event, session) => {
            setEvent(event);
            setUser(session?.user);
            if (session) {
                const isAdminResponse = await supabase.from("admins").select().eq("id", session.user.id).single();
                setIsAdmin(!!isAdminResponse.data);
                setToken(session.access_token);
            } else {
                setIsAdmin(false);
                setToken("");
            }
            setIsLoading(false);
        });

        fetchUser();
    }, []);

    return { user, event, isAdmin, isLoading, token };
};

export default useAuth;
