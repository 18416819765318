import { createContext } from "react";
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import { ChakraProvider, Spinner, Stack } from "@chakra-ui/react";
import { Layout } from "./components/Layout";
import useAuth from "./config/useAuth";

import Landing from "./pages/landing";
import Members from "./pages/members/Members";
import News from "./pages/news/News";
import NewsArticle from "./pages/news/NewsArticle";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import { AuthChangeEvent, User } from "@supabase/supabase-js";
import Profile from "./pages/Profile";
import CreateArticle from "./pages/news/CreateArticle";
import Statutes from "./pages/statutes/Statutes";
import Buoys from "./pages/buoys/Buoys";
import EditBuoys from "./pages/buoys/EditBuoys";
import Store from "./pages/store";
import CreateProduct from "./pages/store/CreateProduct";
import About from "./pages/about";
import Board from "./pages/board";
import EditBoardMember from "./pages/board/EditBoardMember";
import Regattas from "./pages/regattas";
import Regatta from "./pages/regattas/Regatta";
import CreateRegatta from "./pages/regattas/CreateRegatta";
import Gallery from "./pages/gallery";
import Images from "./pages/gallery/Images";
import Neptun from "./pages/neptun";
import EditMember from "./pages/members/EditMember";
import Abstracts from "./pages/abstracts";
import Application from "./pages/application";
import ReportProblem from "./pages/problems/ReportProblem";
import Problems from "./pages/problems/Problems";
import Events from "./pages/events";
import Event from "./pages/events/Event";
import CreateEvent from "./pages/events/CreateEvent";
import Notices from "./pages/notices";
import CreateNotice from "./pages/notices/CreateNotice";
import NotFound from "./pages/404";

export const AuthContext = createContext<{
    event: AuthChangeEvent | undefined;
    user: User | null | undefined;
    isAdmin: boolean;
    token: string;
}>({
    event: undefined,
    user: null,
    isAdmin: false,
    token: "",
});

function App() {
    const { event, user, isAdmin, isLoading, token } = useAuth();

    const isAdminLoader = (isAdmin: boolean) => {
        if (!isAdmin) {
            return redirect("/");
        }
        return null;
    };
    const isUserLoader = (isUser: boolean) => {
        if (!isUser) {
            return redirect("/");
        }
        return null;
    };

    if (isLoading) {
        return (
            <Stack justifyContent={"center"} alignItems="center">
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            </Stack>
        );
    }

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout />,
            children: [
                {
                    index: true,
                    element: <Landing />,
                },
                {
                    path: "nyheter",
                    children: [
                        { index: true, element: <News /> },
                        {
                            path: "artikkel/:id",
                            element: <NewsArticle />,
                        },
                        {
                            path: "opprett",
                            element: <CreateArticle />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                        {
                            path: "opprett/:id",
                            element: <CreateArticle />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                    ],
                },
                {
                    path: "om",
                    element: <About />,
                },
                {
                    path: "styret",
                    children: [
                        { index: true, element: <Board /> },
                        {
                            path: "opprett",
                            element: <EditBoardMember />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                        {
                            path: "rediger/:id",
                            element: <EditBoardMember />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                    ],
                },
                {
                    path: "seilaser",
                    children: [
                        { index: true, element: <Regattas /> },
                        {
                            path: "seilas/:id",
                            element: <Regatta />,
                        },
                        {
                            path: "opprett",
                            element: <CreateRegatta />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                        {
                            path: "rediger/:id",
                            element: <CreateRegatta />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                    ],
                },
                {
                    path: "glemtpassord",
                    element: <ResetPassword />,
                },
                { path: "nybruker", element: <ResetPassword newUser={true} />, loader: () => isUserLoader(!!user) },
                {
                    path: "soeknad",
                    element: <Application />,
                },
                {
                    path: "rapporter-problem",
                    element: <ReportProblem />,
                },
                {
                    path: "profil",
                    element: <Profile />,
                    loader: () => isUserLoader(!!user),
                },
                {
                    path: "medlemmer",
                    children: [
                        { index: true, element: <Members />, loader: () => isUserLoader(!!user) },
                        {
                            path: ":id",
                            element: <EditMember />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                    ],
                },
                {
                    path: "vedtekter",
                    element: <Statutes />,
                    loader: () => isUserLoader(!!user),
                },
                {
                    path: "boyer",
                    children: [
                        { index: true, element: <Buoys />, loader: () => isUserLoader(!!user) },
                        {
                            path: "rediger",
                            element: <EditBuoys />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                    ],
                },
                {
                    path: "generforsamlinger",
                    element: <Abstracts />,
                    loader: () => isUserLoader(!!user),
                },
                {
                    path: "neptuns",
                    element: <Neptun />,
                    loader: () => isUserLoader(!!user),
                },
                {
                    path: "bildearkiv",
                    children: [
                        { index: true, element: <Gallery />, loader: () => isUserLoader(!!user) },
                        {
                            path: ":folder",
                            element: <Images />,
                            loader: () => isUserLoader(!!user),
                        },
                    ],
                },
                {
                    path: "butikk",
                    children: [
                        { index: true, element: <Store />, loader: () => isUserLoader(!!user) },
                        {
                            path: "opprett",
                            element: <CreateProduct />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                        {
                            path: "opprett/:id",
                            element: <CreateProduct />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                    ],
                },
                {
                    path: "aktiviteter",
                    children: [
                        { index: true, element: <Events />, loader: () => isUserLoader(!!user) },
                        {
                            path: "aktivitet/:id",
                            element: <Event />,
                            loader: () => isUserLoader(!!user),
                        },
                        {
                            path: "opprett",
                            element: <CreateEvent />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                        {
                            path: "rediger/:id",
                            element: <CreateEvent />,
                            loader: () => isAdminLoader(isAdmin),
                        },
                    ],
                },
                {
                    path: "oppslagstavle",
                    children: [
                        { index: true, element: <Notices />, loader: () => isUserLoader(!!user) },
                        {
                            path: "opprett",
                            element: <CreateNotice />,
                            loader: () => isUserLoader(!!user),
                        },
                        {
                            path: "opprett/:id",
                            element: <CreateNotice />,
                            loader: () => isUserLoader(!!user),
                        },
                    ],
                },
                {
                    path: "problemer",
                    element: <Problems />,
                },
                {
                    path: "innlogging",
                    element: <Login />,
                },
                { path: "*", element: <NotFound /> },
            ],
        },
    ]);

    return (
        <ChakraProvider>
            <AuthContext.Provider value={{ event, user, isAdmin, token }}>
                <RouterProvider router={router} />
            </AuthContext.Provider>
        </ChakraProvider>
    );
}

export default App;
